import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Option from "./Option";
import styles from "./Options.module.css";

/**
 * *More segregation is required
 * !Segregate Options into individual Option and map them after stroring them into an array
 */

const Options = (props) => {
  const { logout } = useAuth0();

  return (
    <div className={styles.sidebar}>
      <Option
        isCollapsed={props.isCollapsed}
        text={"Scenario Creation"}
        icon={"cases"}
        onClick={props.setSelectedOption.bind(null, 0)}
        active={props.selectedOption === 0 ? true : false}
      />
      <Option
        isCollapsed={props.isCollapsed}
        text={"Demand & Capacity analysis"}
        icon={"store"}
        onClick={props.setSelectedOption.bind(null, 6)}
        active={props.selectedOption === 6 ? true : false}
      />
      <Option
        isCollapsed={props.isCollapsed}
        text={"Transportation Lane analysis"}
        icon={"language"}
        onClick={props.setSelectedOption.bind(null, 1)}
        active={props.selectedOption === 1 ? true : false}
      />
      <Option
        isCollapsed={props.isCollapsed}
        text={"Baseline Capacity analysis"}
        icon={"grid_view"}
        onClick={props.setSelectedOption.bind(null, 2)}
        active={props.selectedOption === 2 ? true : false}
      />
      <Option
        isCollapsed={props.isCollapsed}
        text={"Baseline Transportation analysis"}
        icon={"location_on"}
        onClick={props.setSelectedOption.bind(null, 4)}
        active={props.selectedOption === 4 ? true : false}
      />
      <Option
        isCollapsed={props.isCollapsed}
        text={"NEW Future State Scenarios"}
        icon={"insights"}
        onClick={props.setSelectedOption.bind(null, 7)}
        active={props.selectedOption === 7 ? true : false}
      />

      <Option
        isCollapsed={props.isCollapsed}
        text={"Logout"}
        icon={"logout"}
        onClick={logout.bind(null, {
          returnTo: "https://www.optmlanalytics.com/",
        })}
      />
    </div>
  );
};

export default Options;
